import "isomorphic-fetch";
import { getSelectorsByUserAgent } from "react-device-detect";
import { matchRoute } from "../support/helpers";
import {
    setNavItems,
    setStyling,
    setUserInfo,
    setAssemblies,
    setMode,
    setSavedProperties,
    setIsMobile,
} from "./rootReducer";
import {
    pageDataTypes,
    pageTypes,
    getIdByPageType,
    addPage,
    addAgentPage,
} from "./pagesReducer";

const bulkPageReducer = (
    dispatch,
    data,
    history,
    url,
    userAgent = undefined,
    origin
) => {
    let nicheDomain2ndLevel;
    if (data?.styling) {
        dispatch(setNavItems({ styling: data?.styling, origin }));
        dispatch(
            setSavedProperties(
                data.styling?.userInfo4Session?.dIAccountInfo?.savedProperties
                    ?.userProperties
            )
        );
        dispatch(setStyling(data?.styling));
        nicheDomain2ndLevel = data?.styling?.nicheDomain2ndLevel;
    }
    if (data?.user_info) {
        dispatch(setUserInfo(data.user_info?.userInfo));
    }
    if (data?.assemblies) {
        dispatch(setAssemblies(data.assemblies));
    }
    if (data?.mode) {
        dispatch(setMode(data.mode));
    }
    // This will reduce the page data by the page data type (state, nicheItem, listing...)

    const dataType = pageDataTypes.find(
        (pageType) => pageType === data?.pageType
    );
    let pageType = pageTypes[dataType];
    let pageHeaderInfo;

    if (dataType === "nicheItem" && data.isOffmarket) {
        pageType = pageTypes.offmarket;
    }

    const dataByType = dataType
        ? data[dataType === "nicheItem_development" ? "nicheItem" : dataType]
        : undefined;
    const id = getIdByPageType(pageType, dataByType);

    if (pageType === "agents") {
        dispatch(addAgentPage({ id, data }));
        pageHeaderInfo = data?.agent?.pageHeaderInfo;
    } else if (pageType) {
        const reducerData =
            pageType === "brokerAgents"
                ? dataByType
                : {
                      ...dataByType,
                      listingsPreview: data?.listingsPreview,
                      gis: data?.gis,
                      searchResults: data?.searchResults,
                  };
        dispatch(addPage({ id, pageType, data: reducerData }));
        pageHeaderInfo = reducerData?.pageHeaderInfo;
    }
    if (data?.pageType === "index") {
        dispatch(
            addPage({
                pageType: "index",
                data: {
                    pageHeaderInfo: data?.pageHeaderInfo,
                    listingsPreview: data?.listingsPreview,
                },
            })
        );
        pageHeaderInfo = data?.pageHeaderInfo;
    }

    // Determining if the page is not found.
    let isPageNotFound;
    if (url && data?.mode !== "maintenance") {
        const matchedPageTypes = matchRoute(url);
        // The front end has a pageType(s) defined
        if (matchedPageTypes) {
            if (matchedPageTypes.split("|").indexOf(data?.pageType) === -1) {
                isPageNotFound = true;
            }
        }
    }

    if (history) {
        const state = {};
        if (dataType === "nicheItem_development") {
            state.developmentId = id;
            state.nicheItemId = dataByType?.id;
            state.stateId = dataByType?.stateId;
        } else if (dataType === "nicheItem") {
            state.nicheItemId = id;
            state.stateId = dataByType?.stateId;
        } else {
            state[`${dataType}Id`] = id;
        }

        if (isPageNotFound) state.isPageNotFound = isPageNotFound;

        history.push({ ...history.location, state });
    }

    // Setting isMobile in state. This works for both SSR and on client side.
    const resolvedUserAgent = userAgent ? userAgent : navigator.userAgent;
    const { isMobile } = getSelectorsByUserAgent(resolvedUserAgent);
    dispatch(setIsMobile(isMobile));

    return {
        pageHeaderInfo,
        pageNumber: data?.listingsPreview?.page_number,
        appEnv: data?.styling?.runEnvironment,
        nicheDomain2ndLevel,
        locationState: {
            isPageNotFound,
            initialPage:
                data?.pageType !== "index" || url === "/" || url === "/index"
                    ? data?.pageType
                    : undefined,
            agentId: data?.agent?.agentInfo?.agent_name?.more?.internalAgentID,
            stateId: data?.state?.code || data?.nicheItem?.stateId,
            nicheItemId: data?.nicheItem?.id,
            listingId: data?.listing?.identifiers?.id,
            developmentId: data?.nicheItem?.developmentID,
        },
    };
};

export default bulkPageReducer;
